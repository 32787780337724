<template>
  <div id="app">

    <div class="selectors">
      <div class="selector" v-for="(selector, name) in globalSelectors" :key="name">
        <label class="calcLabel" :for="name">{{ selector.label }}</label>
        <AppSelect
          :id="name"
          :options="globalSelectors[name].options"
          class="calcSelect"
          @input="getPreset"
          v-model="selectedConstruction[name]"
        />
      </div>
      <div class="selector flex-end">
        <button>Добавить в корзину</button>
      </div>
    </div>

    <h1 class="calcTitle">Параметры конструкции</h1>

    <div class="calcViewport">

      <div class="viewport">

        <div class="dimension dimension-height dimension__line dimension__line-v">
          <div
              v-if="selectedConstruction.leaf && selectedConstruction.leaf.value==='leaf' && selectedConstruction.constructionMaterial.value !== 'aluminium' && casementLeaf"
              class="dimension__wrapper dimension__wrapper-leaf dimension__arrows dimension__arrows-v"
          >
            <input
                class="dimension__input dimension__input-v dimension__input-leaf"
                type="text"
                :value="getMeasures('height')"
                @input="setheight($event.target.value)"
            />
          </div>
          <div class="dimension__wrapper dimension__arrows dimension__arrows-v">
            <input
                class="dimension__input dimension__input-v"
                type="text"
                :value="getMeasures('height')"
                @input="setHeight($event.target.value)"
            />
          </div>
        </div>

        <div class="constructor">

          <div
              class="frame wrapper"
              :class="setMaterial()"
              v-for="(rim, index) in windowConstruction" :key="index"
          >

            <div class="dimension dimension-width dimension__line dimension__line-h">
              <div v-for="(container, index3) in windowConstruction[index]" :key="index3" class="dimension__wrapper dimension__arrows dimension__arrows-h">
                <AppInput
                    :name="'casement_' + container"
                    :className="'dimension__input dimension__input-h'"
                    :options="measureWidth"
                    :default="measureWidth.default"
                    @input="setData($event)"
                />
              </div>
            </div>

            <div class="up" :style="getCasementColor()"></div>
            <div class="left" :style="getCasementColor()"></div>

            <div class="windows wrapper">
              <div v-for="(column, index2) in windowConstruction[index]" :key="index2" class="for-wrapper">

                <div class="windows-column">
                  <div
                      v-if="selectedConstruction.leaf && selectedConstruction.leaf.value==='leaf' && selectedConstruction.constructionMaterial.value !== 'aluminium'"
                      class="leaf-button"
                      @click="casementLeaf = !casementLeaf"
                  >
                    Ф
                  </div>

                  <div
                      v-if = "selectedConstruction.leaf && selectedConstruction.leaf.value==='leaf' && selectedConstruction.constructionMaterial.value !== 'aluminium' && casementLeaf"
                      class="window"
                      :class="[{'leaf': selectedConstruction.leaf && selectedConstruction.leaf.value==='leaf'  && selectedConstruction.constructionMaterial.value !== 'aluminium' && casementLeaf}, getCasementDirection('default'), getMosquito()]"
                  >

                    <div class="hinges hinges-l">
                      <span class="hinge hinge-u" :style="getCasementColor()"></span>
                      <span class="hinge hinge-d" :style="getCasementColor()"></span>
                    </div>
                    <div
                        class="casement wrapper"
                        :class="getCasementDirection('default')"
                        @click="getCasementDirection()"
                    >
                      <div class="up" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                      <div class="left" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                      <div class="glazingBead wrapper">
                        <div class="up" :style="getCasementColor()"></div>
                        <div class="left" :style="getCasementColor()"></div>
                        <div class="glass">
                          <div class="glassArrows">
                            <div class="glassArrows-middle">
                              <svg>
                                <line x1="0%" y1="50%" x2="100%" y2="50%"/>
                              </svg>
                            </div>
                            <div class="glassArrows-horizontal">
                              <svg>
                                <line x1="0" y1="0" x2="100%" y2="100%"/>
                              </svg>
                              <svg>
                                <line x1="100%" y1="0" x2="0" y2="100%"/>
                              </svg>
                            </div>
                            <div class="glassArrows-vertical">
                              <svg>
                                <line x1="0" y1="0" x2="100%" y2="100%"/>
                              </svg>
                              <svg>
                                <line x1="100%" y1="0" x2="0" y2="100%"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="right" :style="getCasementColor()"></div>
                        <div class="down" :style="getCasementColor()"></div>
                      </div>
                      <div class="right" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                      <div class="down" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                    </div><!-- .casement .wrapper //-->
                    <div class="hinges hinges-r">
                      <span class="hinge hinge-u" :style="getCasementColor()"></span>
                      <span class="hinge hinge-d" :style="getCasementColor()"></span>
                    </div>
                  </div><!-- .window //-->

                  <div v-if="selectedConstruction.leaf && selectedConstruction.leaf.value==='leaf' && selectedConstruction.constructionMaterial.value !== 'aluminium' && casementLeaf" class="impost impost-horizontal" :style="getCasementColor()"></div>


                  <div class="window" :class="[{'stvorka': selectedConstruction.leaf && selectedConstruction.leaf.value==='leaf' && selectedConstruction.constructionMaterial.value !== 'aluminium' && casementLeaf}, getCasementDirection('default'), getMosquito()]">

                    <div class="hinges hinges-l">
                      <span class="hinge hinge-u" :style="getCasementColor()"></span>
                      <span class="hinge hinge-d" :style="getCasementColor()"></span>
                    </div>
                    <div
                        class="casement wrapper"
                        :class="getCasementDirection('default')"
                        @click="getCasementDirection()"
                    >
                      <div class="up" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                      <div class="left" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                      <div class="glazingBead wrapper">
                        <div class="up" :style="getCasementColor()"></div>
                        <div class="left" :style="getCasementColor()"></div>
                        <div class="glass">
                          <div class="glassArrows">
                            <div class="glassArrows-middle">
                              <svg>
                                <line x1="0%" y1="50%" x2="100%" y2="50%"/>
                              </svg>
                            </div>
                            <div class="glassArrows-horizontal">
                              <svg>
                                <line x1="0" y1="0" x2="100%" y2="100%"/>
                              </svg>
                              <svg>
                                <line x1="100%" y1="0" x2="0" y2="100%"/>
                              </svg>
                            </div>
                            <div class="glassArrows-vertical">
                              <svg>
                                <line x1="0" y1="0" x2="100%" y2="100%"/>
                              </svg>
                              <svg>
                                <line x1="100%" y1="0" x2="0" y2="100%"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="right" :style="getCasementColor()"></div>
                        <div class="down" :style="getCasementColor()"></div>
                      </div>
                      <div class="right" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                      <div class="down" :style="getCasementColor()">
                        <span class="handle-s">
                          <span class="handle-l"></span>
                        </span>
                      </div>
                    </div><!-- .casement .wrapper //-->
                    <div class="hinges hinges-r">
                      <span class="hinge hinge-u" :style="getCasementColor()"></span>
                      <span class="hinge hinge-d" :style="getCasementColor()"></span>
                    </div>
                  </div><!-- .window //-->

                </div><!-- .windows-column //-->

              <div v-if="parseInt(index2) !== windowConstruction[index]-1" class="impost impost-vertical" :style="getCasementColor()"></div>

              </div><!-- v-for //-->
            </div><!-- .windows .wrapper //-->

            <div class="right" :style="getCasementColor()"></div>
            <div class="down" :style="getCasementColor()"></div>
          </div><!-- .frame .wrapper //-->

          <div class="sill" :class="getSill()">
            <div class="sill__up" :style="getSill('color')">
              <span class="corner corner-l"></span>
              <span class="corner corner-r"></span>
            </div>
            <div class="sill__middle" :style="getSill('color')">
              <span class="edge edge-l">
                <span class="corner corner-l"></span>
              </span>
              <span class="edge edge-r">
                <span class="corner corner-r"></span>
              </span>
            </div>
            <div class="sill__down" :style="getSill('color')"></div>
          </div>

        </div><!-- .constructor //-->
      </div>

    </div>

    <div class="selectors">
      <div v-for="(selector, name) in optionSelectors" :key="name" class="selector" :id="name+'_container'">
        <div v-if="name !== 'leaf'">
          <label class="calcLabel" :for="name">
            {{ selector.label }}
            <i v-if="optionSelectors[name].suggestion" class="question">
              <svg version="1.1" class="icon-question" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                <path d="M8,2c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S4.7,2,8,2 M8,1C4.1,1,1,4.1,1,8s3.1,7,7,7s7-3.1,7-7S11.9,1,8,1L8,1z M7.4,9.8
                    c-0.3-0.3-0.4-0.6-0.4-1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.6s0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.3-0.6
                    c0-0.2-0.1-0.5-0.3-0.6C8.4,5.3,8.1,5.2,7.7,5.2c-0.7,0-1.2,0.3-1.7,0.8L5.3,5.2C5.9,4.4,6.8,4,7.9,4c0.7,0,1.3,0.2,1.8,0.5
                    c0.4,0.4,0.7,0.8,0.7,1.4c0,0.3-0.1,0.6-0.2,0.9C9.9,7,9.8,7.2,9.6,7.4C9.3,7.6,9.1,7.7,8.9,7.9C8.7,8,8.5,8.2,8.4,8.3
                    C8.2,8.5,8.2,8.7,8.2,8.9c0,0.2,0.1,0.4,0.3,0.5L7.4,9.8z M8.4,12c-0.2,0.2-0.4,0.2-0.6,0.2S7.4,12.2,7.2,12S7,11.6,7,11.4
                    C7,11.2,7,11,7.2,10.8s0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6C8.6,11.6,8.6,11.8,8.4,12z"
                />
              </svg>
            </i>
          </label>
          <AppSelect
            :id="name"
            :options="setOptions(name, optionSelectors[name].options)"
            :default="getDefault(name, optionSelectors[name].options)"
            class="calcSelect"
            v-model="selectedConstruction[name]"
            @input="changeParams(name, $event)"
          />
        </div>
        <div v-else-if="name == 'leaf' && selectedConstruction.constructionMaterial.value !== 'aluminium'">
          <label class="calcLabel" :for="name">
            {{ selector.label }}
            <i v-if="optionSelectors[name].suggestion" class="question">
              <svg version="1.1" class="icon-question" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                <path d="M8,2c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S4.7,2,8,2 M8,1C4.1,1,1,4.1,1,8s3.1,7,7,7s7-3.1,7-7S11.9,1,8,1L8,1z M7.4,9.8
                    c-0.3-0.3-0.4-0.6-0.4-1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.6s0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.3-0.6
                    c0-0.2-0.1-0.5-0.3-0.6C8.4,5.3,8.1,5.2,7.7,5.2c-0.7,0-1.2,0.3-1.7,0.8L5.3,5.2C5.9,4.4,6.8,4,7.9,4c0.7,0,1.3,0.2,1.8,0.5
                    c0.4,0.4,0.7,0.8,0.7,1.4c0,0.3-0.1,0.6-0.2,0.9C9.9,7,9.8,7.2,9.6,7.4C9.3,7.6,9.1,7.7,8.9,7.9C8.7,8,8.5,8.2,8.4,8.3
                    C8.2,8.5,8.2,8.7,8.2,8.9c0,0.2,0.1,0.4,0.3,0.5L7.4,9.8z M8.4,12c-0.2,0.2-0.4,0.2-0.6,0.2S7.4,12.2,7.2,12S7,11.6,7,11.4
                    C7,11.2,7,11,7.2,10.8s0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.4,0.2,0.6C8.6,11.6,8.6,11.8,8.4,12z"
                />
              </svg>
            </i>
          </label>
          <AppSelect
              :id="name"
              :options="setOptions(name, optionSelectors[name].options)"
              :default="getDefault(name, optionSelectors[name].options)"
              class="calcSelect"
              v-model="selectedConstruction[name]"
              @input="changeParams(name, $event)"
          />
        </div>
      </div>
      <div class="selector w-100">
        <button>Добавить в корзину</button>
      </div>
    </div>

  </div>
</template>

<script>
import AppSelect from "./components/AppSelect.vue";
import AppInput from './components/AppInput.vue';

const axios = require("axios");

export default {
  name: "Calculator",
  components: {
    AppSelect,
    AppInput
  },
  data() {
    return {
      responseData: {},
      responseLoading: true,
      responseError: false,
      globalSelectors: {
        constructionType: {
          label: "Тип конструкции",
          options: [
            { label: "Окно", value: "window" },
            { label: "Балконный блок", value: "balcony" },
            { label: "Входная дверь", value: "entranceDoor" },
            { label: "Раздвижная дверь", value: "slidingDoor" }
          ]
        },
        constructionForm: {
          label: "Форма конструкции",
          options: [
            { label: "Одностворчатая", value: "1" },
            { label: "Двустворчатая", value: "2" },
            { label: "Трехстворчатая", value: "3" },
            { label: "Четырехстворчатая", value: "4" },
            { label: "Пятистворчатая", value: "5" },
            { label: "Шестистворчатая", value: "6" }
          ]
        },
        constructionMaterial: {
          label: "Материал",
          options: [
            { label: "ПВХ", value: "pvh" },
            { label: "Алюминий", value: "aluminium" },
            { label: "Дерево", value: "wood" }
          ]
        }
      },
      optionSelectors: {},
      constructionElements: {},
      selectedConstruction: {},
      casementOpening: null,
      casementLeaf: false,
      columns: 3,
      rims: 1,
      openingCounter: 0,
      measureWidth: {
        default: 0,
        min: 0,
        max: 0,
        error: null,
        label: 'Ширина'
      },
      windowConstruction: []
      // glassWidth: 80,
      // glassHeight: 238,
    };
  },
  methods: {
    setData(event){
      console.log('setData: ' + event);
    },
    getMosquito(){
      if (this.selectedConstruction.mosquito && this.selectedConstruction.mosquito.value !== 'none'){
        return 'mosquito'
      }
    },
    getCasementDirection(param){
      if (this.constructionElements.casement) {
        if (param !== 'default') {
          this.openingCounter++
          if (this.openingCounter > this.constructionElements.casement.openings.length - 1) {
            this.openingCounter = 0;
          }
        }

        this.casementOpening = this.constructionElements.casement.openings[this.openingCounter];
        this.selectedConstruction.casement = {};
        this.selectedConstruction.casement.opening = this.casementOpening;
        return this.casementOpening
      }
    },
    getCasementColor(){
      if (this.selectedConstruction.lamination && this.selectedConstruction.lamination.value !== 'none'){
        if (this.selectedConstruction.lamination.value === 'inside' || this.selectedConstruction.lamination.value === 'both' ) {
          return 'background-color: #' + this.selectedConstruction.laminationInside.value
        }
      }
    },
    getSill(param){
      if (this.selectedConstruction.sillInside){
        if (this.selectedConstruction.sillInside.value !== "sillInside"){
          return 'hidden'
        }
        if (param == 'color'){
          return 'background-color: #' + this.selectedConstruction.sillInsideColor.value
        }
      }
    },
    getMeasures(measure){
      if (this.constructionElements.casement){
        this.selectedConstruction[measure] = this.constructionElements.casement.measures[measure].default;

        if (this.selectedConstruction.casement) {
          this.measureWidth.default = this.constructionElements.casement.measures.width.default
          if (this.selectedConstruction.casement.opening == 'deaf' || this.selectedConstruction.casement.opening == 'blind') {
            this.measureWidth.min = this.constructionElements.casement.measures.width.min.deaf;
            this.measureWidth.max = this.constructionElements.casement.measures.width.max.deaf;
          } else {
            this.measureWidth.min = this.constructionElements.casement.measures.width.min.fold;
            this.measureWidth.max = this.constructionElements.casement.measures.width.max.fold;
          }
        }

        return this.constructionElements.casement.measures[measure].default
      }
    },
    getDefault(name, array){
      if (name === "glass" && this.selectedConstruction.profile) {
        let start = this.selectedConstruction.profile.chained[0];
        return array[start]
      } else {
        return array[0]
      }
    },
    setOptions(name, array){
      if (name === "glass" && this.selectedConstruction.profile){
        let start = this.selectedConstruction.profile.chained[0];
        let end = this.selectedConstruction.profile.chained[1];
        let newArray = array.slice(start, end);
        return newArray
      } else {
        return array
      }
    },
    setMaterial(){
      if (this.selectedConstruction.constructionMaterial){
        return this.selectedConstruction.constructionMaterial.value
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if (evt.type !== "keypress"){
        evt.preventDefault();
        evt.stopPropagation();
      }
      else if ((charCode > 31 && (charCode < 48 || charCode > 57))){
        evt.preventDefault();
        evt.stopPropagation();
      }
      else if (evt.target.value.length >= 4){
        evt.preventDefault();
        evt.stopPropagation();
      }
      else {
        return true;
      }
    },
    setWidth(value){
      const regexp = /^-?\d+\.?\d*$/

      if (regexp.test(value) === false){
        this.measureWidth.error = "Только цифры, пожалуйста";
      }
      if (value < this.measureWidth.min){
        this.measureWidth.error = "Ширина створки не может быть меньше " + this.measureWidth.min + "мм";
      }
      else if (value > this.measureWidth.max){
        this.measureWidth.error = "Ширина створки не может быть больше " + this.measureWidth.max + "мм";
      }
      else {
        this.measureWidth.error = null
      }

      console.log(value);
    },
    setHeight(value){
      console.log(value);
    },
    changeParams(name, event){
      Object.keys(event).forEach(function(key){
        if (name == 'slope'){
          if (event[key] == "none") {
            document.getElementById('slopeWidth_container').style.display="none";
            document.getElementById('slopeColor_container').style.display="none";
          } else {
            document.getElementById('slopeWidth_container').style.display="flex";
            document.getElementById('slopeColor_container').style.display="flex";
          }
        }

        if (name == 'sillInside'){
          if (event[key] == "none") {
            document.getElementById('sillInsideWidth_container').style.display="none";
            document.getElementById('sillInsideBrand_container').style.display="none";
            document.getElementById('sillInsideColor_container').style.display="none";
          } else {
            document.getElementById('sillInsideWidth_container').style.display="flex";
            document.getElementById('sillInsideBrand_container').style.display="flex";
            document.getElementById('sillInsideColor_container').style.display="flex";
          }
        }

        if (name == 'sillOutside'){
          if (event[key] == "none") {
            document.getElementById('sillEbbWidth_container').style.display="none";
            document.getElementById('sillOutsideWidth_container').style.display="none";
            document.getElementById('sillOutsideBrand_container').style.display="none";
            document.getElementById('sillOutsideColor_container').style.display="none";
          } else if (event[key] == "ebb") {
            document.getElementById('sillEbbWidth_container').style.display = "flex";
            document.getElementById('sillOutsideWidth_container').style.display="none";
            document.getElementById('sillOutsideBrand_container').style.display="none";
            document.getElementById('sillOutsideColor_container').style.display="none";
          } else if (event[key] == "sillOutside"){
            document.getElementById('sillEbbWidth_container').style.display = "none";
            document.getElementById('sillOutsideWidth_container').style.display="flex";
            document.getElementById('sillOutsideBrand_container').style.display="flex";
            document.getElementById('sillOutsideColor_container').style.display="flex";
          }
        }

        if (name == 'lamination'){
          if (event[key] == "none") {
            document.getElementById('laminationInside_container').style.display="none";
            document.getElementById('laminationOutside_container').style.display="none";
          } else if (event[key] == "inside") {
            document.getElementById('laminationInside_container').style.display="flex";
            document.getElementById('laminationOutside_container').style.display="none";
          } else if (event[key] == "outside"){
            document.getElementById('laminationInside_container').style.display="none";
            document.getElementById('laminationOutside_container').style.display="flex";
          } else if (event[key] == "both") {
            document.getElementById('laminationInside_container').style.display = "flex";
            document.getElementById('laminationOutside_container').style.display = "flex";
          }
        }
      });
    },
    async getPreset() {
      if (
        (typeof this.selectedConstruction.constructionType !== 'undefined') &&
        (typeof this.selectedConstruction.constructionForm !== 'undefined') &&
        (typeof this.selectedConstruction.constructionMaterial !== 'undefined')
      ) {
        this.selectedConstruction.constructionPreset =
          this.selectedConstruction.constructionType.value + '_' +
          this.selectedConstruction.constructionMaterial.value + '_' +
          this.selectedConstruction.constructionForm.value;

        const presetURL = "../presets/" + this.selectedConstruction.constructionPreset + ".json";

        try {
          const response = await axios.get(presetURL);

          this.responseLoading = false;
          this.responseData = response.data;
          this.optionSelectors = this.responseData.defaultConstruction.params;
          this.constructionElements = this.responseData.defaultConstruction.elements;

          this.windowConstruction = [];
          for (let index = 0; index < this.responseData.defaultConstruction.global.type.rims.length; index++){
            this.windowConstruction.push(this.responseData.defaultConstruction.global.type.rims[index].length);
            this.columns = this.responseData.defaultConstruction.global.type.rims[index].length
          }
          // this.columns = this.responseData.defaultConstruction.global.type.rims[0].length
          this.rims = this.responseData.defaultConstruction.global.type.rims.length
        } catch (error) {
          this.responseError = true;
          console.warn(error);
        }
      }
    }
  }
};
</script>

<style lang="stylus">
$main-font-color = #1f1f1f
$main-font-size = 14px
$main-background-color = #ffffff

$measure-size = 28px
$measure-margin = 13px
$measure-margin-delta = $measure-size + $measure-margin
$measure-input-height = 26px
$measure-input-width = 90px
$measure-input-default = #c1cbd4
$measure-input-selected = #4e84f4
$measure-arrow-size = 7px

$glass-height = 238px
$glass-width = 118px

$main-border-color = #4e7a9c;
$main-glass-color = #a0c6ff;
$main-window-color = #fff;

@import 'assets/css/viewport.styl';
@import 'assets/css/measure.styl';
@import 'assets/css/element.styl';
@import 'assets/css/corners.styl';
@import 'assets/css/hinges.styl';
@import 'assets/css/handles.styl';
@import 'assets/css/sill.styl';
@import 'assets/css/animations.styl';

#calculator
  flex-direction column
  width 100%
  height 420px
  display flex
  justify-content center
  align-items center
  background-color $main-background-color
  border-radius 4px
  margin-bottom 60px

.viewport
  display flex
  flex 0 1 auto
  height auto
  flex-direction row
  flex-wrap wrap
  user-select none

.constructor
  display flex
  flex 1
  align-items center
  justify-content center
  flex-direction row
  flex-wrap wrap

.frame.wrapper
  flex 0 1 auto
  position relative

.windows.wrapper
  display flex
  flex-direction row
  position relative
  flex 1

.windows-column
  &:not(:last-child)
    margin-right 0px

// Углы рам --> 2DO -- переработать
.skew-0
  transform skew(0, 0)
.skew-4
  transform skew(0, 4deg)
.skew-10
  transform: skew(0, 10deg)
.skew-24
  transform: skew(0, 24deg)
.skew-36
  transform: skew(0, 36deg)
.skew-42
  transform: skew(0, 42deg)

.frame
  &.skew-4
    transform skew(0, 4deg)
    transform-origin top left
  &.skew-10
    transform skew(0, 10deg)
    transform-origin top left
</style>
