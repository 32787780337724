<template>
  <div class="tooltip-box">
    <slot />
    <div class="tooltip" :class="{ 'hidden' : !isError }">
      <span class="text">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTooltip",
  props: ['text', 'isError']
}
</script>

<style lang="stylus" scoped>
.tooltip-box
  position relative
  display inline-block
  z-index 10

.tooltip-box
  &:hover
    .tooltip
      opacity 1

.tooltip
  color #ffffff
  text-align center
  padding 5px 0
  border-radius 2px
  width 138px
  bottom calc(28px + 8px)
  left -23px
  opacity 0
  transition opacity 1s
  position absolute
  font-size 13px
  background: #ff4242

.text
  &:after
    content " "
    position absolute
    top 100%
    left 50%
    margin-left -5px
    border-width 5px
    border-style solid
    border-color #ff4242 transparent transparent transparent

.hidden
  display none !important
</style>
