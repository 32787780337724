<template>
  <AppTooltip :text="object.error" :isError="tooltip">
    <input
      type="text"
      :name = "name"
      :class="[className, {'error': object.error }]"
      :value="selectedValue"
      @input="dataUpdate($event.target.value)"
      @keypress="isNumber($event)"
      @paste="isNumber($event)"
      @blur="isError($event)"
    />
  </AppTooltip>
</template>

<script>
import AppTooltip from "./AppTooltip.vue";

export default {
  name: "AppInput",
  components: { AppTooltip },
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: [Array, Object],
      required: true,
      default: () => []
    },
    default: {
      type: Number,
      required: true,
      default: 0
    },
    className: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      object: {
        error: null
      },
      selectedValue:
        this.default ? this.default:
        this.options.default ? this.options.default:
        this.options.length > 0 ? this.options[0]:
        0,
      tooltip: false
    };
  },
  watch: {
    options: {
      deep: true,
      handler: function(newValue) {
        for (const [key, value] of Object.entries(newValue)) {
          this.object[key] = value;
          // console.log(`${key}: ${value}`);
        }
      }
    },
    default: function(newValue){
      this.selectedValue = parseInt(newValue);
      this.object.name = this.name;
      this.object.value = parseInt(newValue);
      this.$emit("input", this.object);
    }
  },
  mounted(){
    for (const [key, value] of Object.entries(this.options)) {
      this.object[key] = value;
    }
  },
  methods: {
    isNumber: function(event) {
      let charCode = (event.which) ? event.which : event.keyCode;
      if (event.type !== "keypress") {
        event.preventDefault();
        event.stopPropagation();
      } else if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        event.preventDefault();
        event.stopPropagation();
      } else if (event.target.value.length >= 4) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        return true;
      }
    },
    isError(event){
      if (this.object.error){
        event.target.value = this.object.default;
        this.selectedValue = this.object.default;
        this.object.error = null;
        this.tooltip = false;
      }
    },
    dataUpdate(value) {
      const regexp = /^-?\d+\.?\d*$/;

      let valueInt = parseInt(value);
      if (isNaN(valueInt)){
        valueInt = null;
      }

      this.selectedValue = valueInt;

      if (regexp.test(valueInt) === false) {
        this.tooltip = true;
        this.object.error = "Только цифры, пожалуйста";
      } else if (!value || value.length < 1 || value === null || value == ''){
        this.tooltip = true;
        this.object.error = "Значение не может быть пустым";
      } else if (valueInt < this.object.min){
        this.tooltip = true;
        this.object.error = this.object.label + " створки не может быть меньше " + this.object.min + "мм";
      } else if (valueInt > this.object.max) {
        this.tooltip = true;
        this.object.error = this.object.label + " створки не может быть меньше " + this.object.max + "мм";
      } else {
        this.object.error = null;
        this.tooltip = false;
        this.object.name = this.name;
        this.object.value = valueInt;
        this.$emit("input", this.object);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.dimension__input
  padding 0
  margin 0
  display flex
  width $measure-input-width
  height $measure-input-height
  border solid 1px $measure-input-default
  border-radius 4px
  text-align center
  transition border-color .27s
  font-weight 300
  color $main-font-color
  font-size $main-font-size
  background-color $main-background-color
  outline 0
  z-index 5
  &.dimension__input-leaf
    width 56px
  &.dimension__input-v
    transform rotate(-90deg)
    transform-origin center
  &:focus
    border-color: $measure-input-selected
  &.error
    border-color #ff4242
    &:focus
      border-color #ff4242
</style>
