<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected.label }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: [Array, Object],
      required: true,
      default: () => [{ label: "Забытый option", value: null }]
    },
    default: {
      type: [Array, Object],
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  watch: {
    default: {
      deep: true,
      handler() {
        this.selected = this.options[0];
        this.$emit("input", this.selected);
      }
    }
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  }
};
</script>

<style lang="stylus" scoped>
.custom-select
  position relative
  width 100%
  height 46px
  text-align left
  outline none
  font-size 13px

  .selected
    display flex
    height 100%
    align-items center
    background-color #fff
    border-radius 4px
    color #525860
    padding-left 1em
    padding-right 1.5em
    cursor pointer
    user-select none
    &.open
      border-radius: 4px 4px 0px 0px
      box-shadow: 0px 15px 15px rgba(125, 125, 125, .2);
    &:after
      top calc(50% - 3px)
      right 1em
      width 5px
      height 5px
      position absolute
      content ""
      border-bottom solid 1px #000
      border-right solid 1px #000
      transform rotate(45deg);
      transition all .27s
    &.open:after
        transform rotate(-135deg);
        border-color #4e84f4

  .items
    left 0
    right 0
    color #525860
    border-radius 0px 0px 4px 4px
    overflow hidden
    position absolute
    background-color #fff
    z-index 1000
    box-shadow: 0px 15px 15px rgba(125, 125, 125, .25);
    border-top solid 1px #ebf2f7
    div
      color #525860
      padding 12px 1em
      cursor pointer
      user-select none
      transition background-color .27s
      &:hover
        background-color #ebf2f7

.selectHide
  display none
</style>
